import { DateFilterTypes } from "./dateFilter.enum";
import { StringMultiSelectOption } from "../../mxts/selectOption.types";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export function getDateFilterOptions(): StringMultiSelectOption[] {
    return [
        {
            value: DateFilterTypes.TODAY,
            text: getI18nLocaleObject(namespaceList.widgetDateFilter, "today"),
        },
        {
            value: DateFilterTypes.TOMORROW,
            text: getI18nLocaleObject(namespaceList.widgetDateFilter, "tomorrow"),
        },
        {
            value: DateFilterTypes.THIS_WEEK,
            text: getI18nLocaleObject(namespaceList.widgetDateFilter, "thisWeek"),
        },
    ];
}
