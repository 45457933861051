import * as React from "react";

import { FormSpec, multiSelectStylePicker } from "../../../form-specs";

import { DateFilter } from "./DateFilter";
import { PageWidgetSpec } from "../../widget";
import { StringMultiSelectOption } from "../../mxts/selectOption.types";
import { WidgetGroup } from "../../widget.enum";
import { getDateFilterOptions } from "./dateFilter.util";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    dateOptionMultiSelect: StringMultiSelectOption[];
    styleIds: string[];
}

const TARGET = ["date-filter"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "page-date-filter-options",
    name: getI18nLocaleObject(namespaceList.widgetDateFilter, "dateFilterWidgetOption"),
    pluralName: getI18nLocaleObject(namespaceList.widgetDateFilter, "dateFilterWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "dateOptionMultiSelect",
                                label: getI18nLocaleObject(namespaceList.widgetDateFilter, "dateFilterOptions"),
                                type: "multiselect",
                                optionList: () => getDateFilterOptions(),
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [[multiSelectStylePicker("styleIds", TARGET)]],
                },
            ],
        },
    ],
};

export const dateFilterWidget: PageWidgetSpec<WidgetOptions> = {
    id: "pageDateFilterWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.widgetDateFilter, "dateFilterWidget"),
    description: getI18nLocaleObject(namespaceList.widgetDateFilter, "dateFilterDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: () => ({
        dateOptionMultiSelect: [],
        styleIds: [],
    }),
    async render(widget, context) {
        return <DateFilter options={widget.options} context={context} />;
    },
};
