import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { useDispatch, useSelector } from "react-redux";

import { ActionType } from "../../../redux/actions";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../utils/constants";
import { DateFilterTypes } from "./dateFilter.enum";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getI18nLocaleString } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

interface DateFilterProps {
    options: WidgetOptions;
    context: CMSProvidedProperties;
}

export const DateFilter = (props: DateFilterProps) => {
    const { options } = props;
    const [selectedDateOption, setSelectedDateOption] = React.useState<string | undefined>();
    const dynamicFilter = useSelector((state: State) => state.dynamicFilter);
    const dispatch = useDispatch();
    React.useEffect(() => {
        const { startdate, enddate } = dynamicFilter;
        const currentDate = moment();
        const today = currentDate.format(DATE_FORMAT.DEFAULT);
        const tomorrow = currentDate.add(1, "days").format(DATE_FORMAT.DEFAULT);
        const diffStartAndEndDate = startdate === today ? moment(enddate, DATE_FORMAT.DEFAULT).diff(moment(startdate, DATE_FORMAT.DEFAULT), "days") : undefined;
        if (startdate && enddate) {
            if (startdate === enddate) {
                if (startdate === today && enddate === today) {
                    setSelectedDateOption(DateFilterTypes.TODAY);
                } else if (startdate === tomorrow && enddate === tomorrow) {
                    setSelectedDateOption(DateFilterTypes.TOMORROW);
                }
            } else if (startdate !== enddate) {
                if (diffStartAndEndDate && diffStartAndEndDate === 7) {
                    setSelectedDateOption(DateFilterTypes.THIS_WEEK);
                } else {
                    setSelectedDateOption(undefined);
                }
            }
        } else {
            setSelectedDateOption(undefined);
        }
    }, [dynamicFilter]);

    const getDateFilterClassnames = (option: string) => {
        const isDateOptionSelected = selectedDateOption?.toLowerCase() === option.toLowerCase();
        return classNames("date-filter-widget__item", { "date-filter-widget__item--selected": isDateOptionSelected });
    };

    const handleOnClick = (selectedOption: string) => {
        const date = moment();
        let payload: { startdate?: string; enddate?: string } = { startdate: date.format(DATE_FORMAT.DEFAULT), enddate: undefined };
        if (selectedOption === DateFilterTypes.TODAY) {
            payload = { startdate: date.format(DATE_FORMAT.DEFAULT), enddate: date.format(DATE_FORMAT.DEFAULT) };
        } else if (selectedOption === DateFilterTypes.TOMORROW) {
            payload = { startdate: date.add(1, "days").format(DATE_FORMAT.DEFAULT), enddate: date.format(DATE_FORMAT.DEFAULT) };
        } else if (selectedOption === DateFilterTypes.THIS_WEEK) {
            payload = { startdate: date.format(DATE_FORMAT.DEFAULT), enddate: date.add(7, "days").format(DATE_FORMAT.DEFAULT) };
        }
        const startDateAction: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.startdate,
            payload,
        };
        dispatch(startDateAction);

        const endDateAction: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.enddate,
            payload,
        };
        dispatch(endDateAction);

        setSelectedDateOption(selectedOption);
    };

    return (
        <ErrorBoundary>
            <div className="date-filter-widget">
                {options.dateOptionMultiSelect.map((dateOption) => (
                    <div key={dateOption.value} className={getDateFilterClassnames(dateOption.value)} onClick={() => handleOnClick(dateOption.value)}>
                        <span className="date-filter-widget__item-label">{getI18nLocaleString(namespaceList.widgetDateFilter, dateOption.value.toLowerCase())}</span>
                    </div>
                ))}
            </div>
        </ErrorBoundary>
    );
};
